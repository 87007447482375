/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
    padding: 60px 0;
    overflow: hidden;
}


.section-title {
    padding-bottom: 40px;

    h2 {
        font-size: 14px;
        font-weight: 500;
        padding: 0;
        line-height: 1px;
        margin: 0 0 5px 0;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: lighten($default, 40);
        font-family: $font-secondary;
    }

    h2::after {
        content: "";
        width: 120px;
        height: 1px;
        display: inline-block;
        background: lighten($primary, 15);
        margin: 4px 10px;
    }

    p {
        margin: 0;
        margin: 0;
        font-size: 36px;
        font-weight: 700;
        text-transform: uppercase;
        font-family: $font-secondary;
        color: $secondary;
    }

}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
    padding-top: 80px;

    .content {
        h3 {
            font-weight: 600;
            font-size: 26px;
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                padding-left: 28px;
                position: relative;
            }

            li+li {
                margin-top: 10px;
            }

            i {
                position: absolute;
                left: 0;
                top: 2px;
                font-size: 20px;
                color: $primary;
                line-height: 1;
            }
        }

        p:last-child {
            margin-bottom: 0;
        }

        .btn-learn-more {
            font-family: $font-primary;
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 1px;
            display: inline-block;
            padding: 12px 32px;
            border-radius: 5px;
            transition: 0.3s;
            line-height: 1;
            color: $primary;
            animation-delay: 0.8s;
            margin-top: 6px;
            border: 2px solid $primary;

            &:hover {
                background: $primary;
                color: #fff;
                text-decoration: none;
            }
        }
    }
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features {

    .nav-tabs {
        border: 0;
    }

    .nav-link {

        border: 1px solid lighten($secondary, 66);
        padding: 15px;
        transition: 0.3s;
        color: $secondary;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            padding-right: 15px;
            font-size: 48px;
        }

        h4 {
            font-size: 18px;
            font-weight: 600;
            margin: 0;
        }


        &:hover {
            color: $primary;
        }


        &.active {
            background: $primary;
            color: $white;
            border-color: $primary;
        }

        @media (max-width: 768px) {
            i {
                padding: 0;
                line-height: 1;
                font-size: 36px;
            }
        }

        @media (max-width: 575px) {
            padding: 15px;

            i {
                font-size: 24px;
            }
        }
    }

    .tab-content {
        margin-top: 30px;
    }

    .tab-pane {
        h3 {
            font-weight: 600;
            font-size: 26px;
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                padding-bottom: 10px;
            }

            i {
                font-size: 20px;
                padding-right: 4px;
                color: $primary;
            }
        }

        p:last-child {
            margin-bottom: 0;
        }
    }
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
    background: $secondary;
    padding: 80px 0;

    h3 {
        color: #fff;
        font-size: 28px;
        font-weight: 700;
    }

    p {
        color: #fff;
    }

    .cta-btn {
        font-family: $font-primary;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 1px;
        display: inline-block;
        padding: 12px 30px;
        border-radius: 50px;
        transition: 0.5s;
        margin: 10px;
        color: #fff;
        background: $primary;

        &:hover {
            background: $white;
            color: $primary;
        }
    }

    @media (max-width: 1024px) {
        background-attachment: scroll;
    }

    @media (min-width: 769px) {
        .cta-btn-container {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services {

    .icon-box {
        padding: 30px;
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        background: $white;
        box-shadow: 0 2px 29px 0 rgba(68, 88, 144, .12);
        transition: all 0.4s ease-in-out;
        width: 100%;
        height: 100%;

        &:hover {
            transform: translateY(-10px);
            box-shadow: 0 2px 35px 0 rgba(68, 88, 144, .2);
        }
    }

    .icon {
        position: absolute;
        left: -20px;
        top: calc(50% - 30px);

        i {
            font-size: 64px;
            line-height: 1;
            transition: 0.5s;
        }

    }

    .title {
        margin-left: 40px;
        font-weight: 700;
        margin-bottom: 15px;
        font-size: 18px;

        a {
            color: $secondary;
            transition: ease-in-out 0.3s;

            &:hover {
                color: $primary;
            }
        }
    }

    .description {
        font-size: 14px;
        margin-left: 40px;
        line-height: 24px;
        margin-bottom: 0;
    }
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio {

    #portfolio-flters {
        list-style: none;
        margin-bottom: 20px;

        li {
            cursor: pointer;
            display: inline-block;
            margin: 0 0 10px 10px;
            font-size: 16px;
            font-weight: 500;
            line-height: 1;
            text-transform: uppercase;
            color: $default;
            transition: all 0.3s ease-in-out;

            &::before {
                content: "[";
                margin-right: 6px;
                color: $white;
                font-size: 18px;
                font-weight: 400;
                transition: all 0.3s ease-in-out;
            }

            &::after {
                content: "]";
                margin-left: 6px;
                color: $white;
                font-size: 18px;
                font-weight: 400;
                transition: all 0.3s ease-in-out;
            }

            &:hover,
            &.filter-active {
                color: $primary;
            }

            &.filter-active {

                &::before,
                &::after {
                    color: $primary;
                }
            }
        }
    }

    .portfolio-item {
        margin-bottom: 30px;

        .portfolio-img {
            overflow: hidden;

            img {
                transition: all 0.8s ease-in-out;
            }
        }

        .portfolio-info {
            opacity: 0;
            position: absolute;
            left: 15px;
            bottom: 0;
            z-index: 3;
            right: 15px;
            transition: all ease-in-out 0.3s;
            background: rgba(#000, .5);
            padding: 10px 15px;

            h4 {
                font-size: 18px;
                color: $white;
                font-weight: 600;
                color: $white;
                margin-bottom: 0px;
            }

            p {
                color: lighten($primary, 40);
                font-size: 14px;
                margin-bottom: 0;
            }

            .preview-link,
            .details-link {
                position: absolute;
                right: 40px;
                font-size: 24px;
                top: calc(50% - 18px);
                color: $white;
                transition: 0.3s;

                &:hover {
                    color: lighten($primary, 20);
                }
            }

            .details-link {
                right: 10px;
            }

        }

        .portfolio-links {
            opacity: 0;
            left: 0;
            right: 0;
            text-align: center;
            z-index: 3;
            position: absolute;
            transition: all ease-in-out 0.3s;

            a {
                color: $white;
                margin: 0 2px;
                font-size: 28px;
                display: inline-block;
                transition: 0.3s;

                &:hover {
                    color: lighten($primary, 20);
                }
            }

        }

        &:hover {
            .portfolio-img img {
                transform: scale(1.2);
            }

            .portfolio-info {
                opacity: 1;
            }
        }
    }
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials {

    .testimonial-item {
        box-sizing: content-box;
        padding: 30px 30px 0 30px;
        margin: 30px 15px;
        text-align: center;
        min-height: 350px;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, .08);

        .testimonial-img {
            width: 90px;
            border-radius: 50%;
            border: 4px solid #fff;
            margin: 0 auto;
        }

        h3 {
            font-size: 18px;
            font-weight: bold;
            margin: 10px 0 5px 0;
            color: #111;
        }

        h4 {
            font-size: 14px;
            color: #999;
            margin: 0;
        }

        .quote-icon-left,
        .quote-icon-right {
            color: lighten($primary, 40);
            font-size: 26px;
        }

        .quote-icon-left {
            display: inline-block;
            left: -5px;
            position: relative;
        }

        .quote-icon-right {
            display: inline-block;
            right: -5px;
            position: relative;
            top: 10px;
        }

        p {
            font-style: italic;
            margin: 0 auto 15px auto;
        }

    }

    .owl-nav,
    .owl-dots {
        margin-top: 5px;
        text-align: center;
    }

    .owl-dot {
        display: inline-block;
        margin: 0 5px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #ddd !important;
    }

    .owl-dot.active {
        background-color: $primary !important;
    }

    @media (max-width: 767px) {
        margin: 30px 10px;
    }
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing {

    .box {
        padding: 20px;
        background: #fff;
        text-align: center;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        position: relative;
        overflow: hidden;
    }

    h3 {
        font-weight: 400;
        margin: -20px -20px 25px -20px;
        padding: 30px 15px;
        font-size: 18px;
        font-weight: 600;
        color: lighten($default, 20);
        background: #f8f8f8;
    }

    h4 {
        font-size: 36px;
        color: $primary;
        font-weight: 600;
        font-family: $font-secondary;
        margin-bottom: 20px;

        sup {
            font-size: 20px;
            top: -15px;
            left: -3px;
        }

        span {
            color: #bababa;
            font-size: 16px;
            font-weight: 300;
        }
    }

    ul {
        padding: 0;
        list-style: none;
        color: $default;
        text-align: center;
        line-height: 20px;
        font-size: 14px;

        li {
            padding-bottom: 16px;
        }

        i {
            color: $primary;
            font-size: 18px;
            padding-right: 4px;
        }

        .na {
            color: #ccc;
            text-decoration: line-through;
        }
    }

    .btn-wrap {
        margin: 20px -20px -20px -20px;
        padding: 20px 15px;
        background: #f8f8f8;
        text-align: center;
    }

    .btn-buy {
        background: $primary;
        display: inline-block;
        padding: 8px 35px 10px 35px;
        border-radius: 50px;
        color: #fff;
        transition: none;
        font-size: 14px;
        font-weight: 400;
        font-family: $font-primary;
        font-weight: 600;
        transition: 0.3s;

        &:hover {
            background: lighten($primary, 10);
        }
    }

    .featured {
        border: 2px solid $primary;

        h3 {
            color: $white;
            background: $primary;
        }
    }

    .advanced {
        width: 200px;
        position: absolute;
        top: 18px;
        right: -68px;
        transform: rotate(45deg);
        z-index: 1;
        font-size: 14px;
        padding: 1px 0 3px 0;
        background: $primary;
        color: $white;
    }

}

/*--------------------------------------------------------------
# F.A.Q
--------------------------------------------------------------*/
.faq {
    padding: 60px 0;

    .faq-list {
        padding: 0;
        list-style: none;

        li {
            padding: 0 0 20px 25px;
        }

        a {
            position: relative;
            font-family: $primary;
            color: $primary;
            font-size: 15px;
            font-weight: 500;
        }

        i {
            font-size: 18px;
            position: absolute;
            left: -25px;
            top: 6px;
        }

        p {
            margin-bottom: 20px;
            font-size: 15px;
        }

        a.collapse {
            color: $primary;
        }

        a.collapsed {
            color: $black;

            &:hover {
                color: $primary;
            }

            i::before {
                content: "\eab2" !important;
            }
        }
    }
}

.bio {
    font-size: 12px;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {
    background: #fff;
    padding: 60px 0;

    .member {
        margin-bottom: 20px;
        overflow: hidden;
        border-radius: 5px;
        background: $white;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.10);

        .member-img {
            position: relative;
            overflow: hidden;
        }

        .memberInfoLink {
            position: absolute;
            left: 0;
            bottom: 30px;
            right: 0;
            opacity: 0;
            transition: ease-in-out 0.3s;
            text-align: center;

            a {
                transition: color 0.3s;
                color: $secondary;
                margin: 0 3px;
                padding-top: 7px;
                border-radius: 50px;
                width: 100%;
                height: 36px;
                background: rgba($primary, .8);
                display: inline-block;
                transition: ease-in-out 0.3s;
                color: $white;

                &:hover {
                    background: lighten($primary, 10);
                }

            }

            i {
                font-size: 18px;
            }
        }

        .social {
            position: absolute;
            left: 0;
            bottom: 30px;
            right: 0;
            opacity: 0;
            transition: ease-in-out 0.3s;
            text-align: center;

            a {
                transition: color 0.3s;
                color: $secondary;
                margin: 0 3px;
                padding-top: 7px;
                border-radius: 50px;
                width: 36px;
                height: 36px;
                background: rgba($primary, .8);
                display: inline-block;
                transition: ease-in-out 0.3s;
                color: $white;

                &:hover {
                    background: lighten($primary, 10);
                }

            }

            i {
                font-size: 18px;
            }
        }

        .member-info {
            padding: 25px 15px;
            min-height: 120px;

            h4 {
                font-weight: 700;
                margin-bottom: 5px;
                font-size: 18px;
                color: $secondary;
            }

            span {
                display: block;
                font-size: 13px;
                font-weight: 400;
                color: lighten($default, 40);
            }

            p {
                font-style: italic;
                font-size: 14px;
                line-height: 26px;
                color: lighten($default, 20);
            }

        }

        &:hover {

            .social {
                opacity: 1;
                bottom: 15px;
            }
        }

        .btn-header {
            font-family: $font-primary;
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 1px;
            display: inline-block;
            padding: 12px 32px;
            border-radius: 50px;
            transition: 0.5s;
            line-height: 1;
            margin: 10px;
            color: $default;
            animation-delay: 0.8s;
            border: 2px solid $primary;

            &:hover {
                background: $primary;
                color: #fff;
                text-decoration: none;
            }
        }
    }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact {

    .info {
        width: 100%;
        background: $white;

        i {
            font-size: 20px;
            color: $primary;
            float: left;
            width: 44px;
            height: 44px;
            background: lighten($primary, 46);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            transition: all 0.3s ease-in-out;
        }

        h4 {
            padding: 0 0 0 60px;
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 5px;
            color: $secondary;
        }

        p {
            padding: 0 0 0 60px;
            margin-bottom: 0;
            font-size: 14px;
            color: lighten($secondary, 20);
        }

        .email,
        .hours,
        .parking,
        .phone {
            margin-top: 40px;
        }

        .email:hover,
        .address:hover,
        .phone:hover {
            i {
                background: $primary;
                color: $white;
            }
        }
    }


    .php-email-form {
        width: 100%;
        background: #fff;

        .form-group {
            padding-bottom: 8px;
        }

        .validate {
            color: red;
            margin: 0 0 15px 0;
            font-weight: 400;
            font-size: 13px;
        }

        .error-message {
            color: #fff;
            background: #ed3c0d;
            text-align: center;
            padding: 15px;
            font-weight: 600;
        }

        .sent-message {
            color: #fff;
            background: #18d26e;
            text-align: center;
            padding: 15px;
            font-weight: 600;
        }

        .loading {
            background: #fff;
            text-align: center;
            padding: 15px;

            &:before {
                content: "";
                display: inline-block;
                border-radius: 50%;
                width: 24px;
                height: 24px;
                margin: 0 10px -6px 0;
                border: 3px solid #18d26e;
                border-top-color: #eee;
                animation: animate-loading 1s linear infinite;
            }

        }

        input,
        textarea {
            border-radius: 0;
            box-shadow: none;
            font-size: 14px;
        }

        input {
            height: 44px;
        }

        textarea {
            padding: 10px 12px;
        }

        button[type="submit"] {
            background: $primary;
            border: 0;
            padding: 10px 24px;
            color: #fff;
            transition: 0.4s;
            border-radius: 50px;

            &:hover {
                background: lighten($primary, 10%);
            }
        }

    }

    @keyframes animate-loading {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
    padding: 15px 0;
    margin-top: 70px;
    background: $highlight;
    min-height: 40px;
    color: $white;

    h2 {
        font-size: 32px;
        font-weight: 300;
    }

    ol {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin: 0;

        li+li {
            padding-left: 10px;
        }

        li+li::before {
            display: inline-block;
            padding-right: 10px;
            color: lighten($secondary, 10);
            content: "/";
        }
    }

    @media (max-width: 768px) {
        .d-flex {
            display: block !important;

        }

        ol {
            display: block;

            li {
                display: inline-block;
            }

        }
    }
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {

    padding-top: 40px;

    .portfolio-details-container {
        position: relative;
    }

    .portfolio-details-carousel {
        position: relative;
        z-index: 1;

        .owl-nav,
        .owl-dots {
            margin-top: 5px;
            text-align: left;
        }

        .owl-dot {
            display: inline-block;
            margin: 0 10px 0 0;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #ddd !important;
        }

        .owl-dot.active {
            background-color: $primary !important;
        }
    }


    .portfolio-info {
        padding: 30px;
        position: absolute;
        right: 0;
        bottom: -70px;
        background: $white;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.10);
        z-index: 2;

        h3 {
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #eee;
        }

        ul {
            list-style: none;
            padding: 0;
            font-size: 15px;

            li+li {
                margin-top: 10px;
            }
        }

    }

    .portfolio-description {
        padding-top: 50px;

        h2 {
            width: 50%;
            font-size: 26px;
            font-weight: 700;
            margin-bottom: 20px;
        }

        p {
            padding: 0 0 0 0;
        }
    }

    @media (max-width: 768px) {
        .portfolio-info {
            position: static;
            margin-top: 30px;
        }
    }

}


/*--------------------------------------------------------------
# teammember Details
--------------------------------------------------------------*/
.teammember-details {

    padding-top: 20px;

    .teammember-details-container {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .teammember-img {
            height: 200px;
            width: 200px;
        }

        .teammember-details-text {
            padding-left: 24px;
        }
    }

    .teammember-details-carousel {
        position: relative;
        z-index: 1;

        .owl-nav,
        .owl-dots {
            margin-top: 5px;
            text-align: left;
        }

        .owl-dot {
            display: inline-block;
            margin: 0 10px 0 0;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #ddd !important;
        }

        .owl-dot.active {
            background-color: $primary !important;
        }
    }


    .teammember-info {
        padding: 30px;
        position: absolute;
        right: 0;
        bottom: -70px;
        background: $white;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.10);
        z-index: 2;

        h3 {
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #eee;
        }

        ul {
            list-style: none;
            padding: 0;
            font-size: 15px;

            li+li {
                margin-top: 10px;
            }
        }

    }

    .teammember-description {
        padding-top: 50px;

        h2 {
            width: 50%;
            font-size: 26px;
            font-weight: 700;
            margin-bottom: 20px;
        }

        p {
            padding: 0 0 0 0;
        }
    }

    @media (max-width: 768px) {
        .teammember-info {
            position: static;
            margin-top: 30px;
        }
    }

}

/*--------------------------------------------------------------
# article Details
--------------------------------------------------------------*/
.article-details {

    padding-top: 20px;

    .article-details-container {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 3px solid $primary;
        border-radius: 12px;
        min-height: 330px;

        img {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }

    .article-details-container-mobile {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: -20px;
        margin-right: -20px;
        margin-top: -20px;
    }

    .article-details-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 24px;
        min-height: 330px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 36px;
        padding-right: 12px;
    }

    .article-details-text-mobile {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 24px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 36px;
        padding-right: 12px;
    }

    .article-details-carousel {
        position: relative;
        z-index: 1;

        .owl-nav,
        .owl-dots {
            margin-top: 5px;
            text-align: left;
        }

        .owl-dot {
            display: inline-block;
            margin: 0 10px 0 0;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #ddd !important;
        }

        .owl-dot.active {
            background-color: $primary !important;
        }
    }


    .article-info {
        padding: 30px;
        position: absolute;
        right: 0;
        bottom: -70px;
        background: $white;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.10);
        z-index: 2;

        h3 {
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #eee;
        }

        ul {
            list-style: none;
            padding: 0;
            font-size: 15px;

            li+li {
                margin-top: 10px;
            }
        }

    }

    .article-description {
        padding-top: 20px;

        h2 {
            width: 50%;
            font-size: 26px;
            font-weight: 700;
            margin-bottom: 20px;
        }

        p {
            padding: 0 0 0 0;
        }
    }

    @media (max-width: 768px) {
        .article-info {
            position: static;
            margin-top: 30px;
        }
    }

}


/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
    background: #fff;
    padding: 60px 0;

    .blog-pagination {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .blog-article {
        margin-bottom: 20px;
        overflow: hidden;
        border-radius: 5px;
        background: $white;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.10);

        .blog-article-img {
            position: relative;
            overflow: hidden;
        }

        .blog-article-link {
            position: absolute;
            left: 0;
            bottom: 30px;
            right: 0;
            opacity: 0;
            transition: ease-in-out 0.3s;
            text-align: center;

            a {
                transition: color 0.3s;
                color: $secondary;
                margin: 0 3px;
                padding-top: 7px;
                border-radius: 50px;
                width: 100%;
                height: 36px;
                background: rgba($primary, .8);
                display: inline-block;
                transition: ease-in-out 0.3s;
                color: $white;

                &:hover {
                    background: lighten($primary, 10);
                }

            }

            i {
                font-size: 18px;
            }
        }

        .blog-article-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 25px 15px;
            min-height: 120px;

            h4 {
                font-weight: 700;
                margin-bottom: 5px;
                font-size: 18px;
                color: $secondary;
            }

            span {
                display: block;
                font-size: 13px;
                font-weight: 400;
                color: lighten($default, 40);
            }

            p {
                font-style: italic;
                font-size: 14px;
                line-height: 26px;
                color: lighten($default, 20);
            }

        }

        &:hover {

            .social {
                opacity: 1;
                bottom: 15px;
            }
        }

        .btn-header {
            font-family: $font-primary;
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 1px;
            display: inline-block;
            padding: 12px 32px;
            border-radius: 50px;
            transition: 0.5s;
            line-height: 1;
            margin: 10px;
            color: $default;
            animation-delay: 0.8s;
            border: 2px solid $primary;

            &:hover {
                background: $primary;
                color: #fff;
                text-decoration: none;
            }
        }
    }

    .blog-article-link-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .blog-feature-article {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
        overflow: hidden;
        border-radius: 5px;
        background: $white;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.10);
        padding-right: 20px;

        .blog-article-img {
            position: relative;
            overflow: hidden;
        }

        .blog-article-link {
            position: absolute;
            left: 0;
            bottom: 30px;
            right: 0;
            opacity: 0;
            transition: ease-in-out 0.3s;
            text-align: center;

            a {
                transition: color 0.3s;
                color: $secondary;
                margin: 0 3px;
                padding-top: 7px;
                border-radius: 50px;
                width: 100%;
                height: 36px;
                background: rgba($primary, .8);
                display: inline-block;
                transition: ease-in-out 0.3s;
                color: $white;

                &:hover {
                    background: lighten($primary, 10);
                }

            }

            i {
                font-size: 18px;
            }
        }

        .blog-article-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 25px 15px;
            min-height: 120px;

            h4 {
                font-weight: 700;
                margin-bottom: 5px;
                font-size: 18px;
                color: $secondary;
            }

            span {
                display: block;
                font-size: 13px;
                font-weight: 400;
                color: lighten($default, 40);
            }

            p {
                font-style: italic;
                font-size: 14px;
                line-height: 26px;
                color: lighten($default, 20);
            }

        }

        &:hover {

            .social {
                opacity: 1;
                bottom: 15px;
            }
        }

        .btn-header {
            font-family: $font-primary;
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 1px;
            display: inline-block;
            padding: 12px 32px;
            border-radius: 50px;
            transition: 0.5s;
            line-height: 1;
            margin: 10px;
            color: $default;
            animation-delay: 0.8s;
            border: 2px solid $primary;

            &:hover {
                background: $primary;
                color: #fff;
                text-decoration: none;
            }
        }
    }
}