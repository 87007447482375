/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: darken($secondary, 10);
  color: $white;
  font-size: 14px;
  text-align: center;
  padding: 30px 0;

  h3 {
    font-size: 36px;
    font-weight: 700;
    color: $white;
    position: relative;
    font-family: $font-secondary;
    padding: 0;
    margin: 0 0 15px 0;
  }

  p {
    font-size: 15;
    font-style: italic;
    padding: 0;
    margin: 0 0 40px 0;
  }

  .social-links {
    margin: 0 0 40px 0;

    a {
      font-size: 18px;
      display: inline-block;
      background: $primary;
      color: $white;
      line-height: 1;
      padding: 8px 0;
      margin-right: 4px;
      border-radius: 50%;
      text-align: center;
      width: 36px;
      height: 36px;
      transition: 0.3s;

      &:hover {
        background: darken($primary, 10);
        color: #fff;
        text-decoration: none;
      }
    }
  }

  .copyright {
    margin: 0 0 5px 0;
  }

  .credits {
    font-size: 13px;
  }
}

.footerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.footerWrapperMobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.footerLocation {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  vertical-align: middle;
}