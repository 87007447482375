/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    overflow: hidden;
    position: relative;
    background: linear-gradient(0deg, rgb(57, 75, 189) 0%, rgb(10, 19, 80) 100%);
    padding: 0;

    .carousel-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        position: relative;
        height: 30vh;
        padding-top: 60px;
    }

    h2 {
        color: #fff;
        margin-bottom: 30px;
        font-size: 48px;
        font-weight: 700;
    }

    p {
        width: 80%;
        animation-delay: 0.4s;
        margin: 0 auto 30px auto;
        color: #fff;
    }

    .carousel-control-prev,
    .carousel-control-next {
        width: 10%;
    }

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        background: none;
        font-size: 48px;
        line-height: 1;
        width: auto;
        height: auto;
    }

    .btn-get-started {
        font-family: $font-primary;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 1px;
        display: inline-block;
        padding: 12px 32px;
        border-radius: 50px;
        transition: 0.5s;
        line-height: 1;
        margin: 10px;
        color: #fff;
        animation-delay: 0.8s;
        border: 2px solid $primary;

        &:hover {
            background: $primary;
            color: #fff;
            text-decoration: none;
        }
    }

    @media (min-width: 1024px) {
        p {
            width: 60%;
        }

        .carousel-control-prev,
        .carousel-control-next {
            width: 5%;
        }
    }

    @media (max-width: 768px) {
        .carousel-container {
            height: 30vh;
        }

        h2 {
            font-size: 28px;
        }
    }

}

.hero-waves {
    display: block;
    width: 100%;
    height: 60px;
    position: relative;
}

.wave1 use {
    animation: move-forever1 10s linear infinite;
    animation-delay: -2s;
}

.wave2 use {
    animation: move-forever2 8s linear infinite;
    animation-delay: -2s;
}

.wave3 use {
    animation: move-forever3 6s linear infinite;
    animation-delay: -2s;
}

@keyframes move-forever1 {
    0% {
        transform: translate(85px, 0%);
    }

    100% {
        transform: translate(-90px, 0%);
    }
}

@keyframes move-forever2 {
    0% {
        transform: translate(-90px, 0%);
    }

    100% {
        transform: translate(85px, 0%);
    }
}

@keyframes move-forever3 {
    0% {
        transform: translate(-90px, 0%);
    }

    100% {
        transform: translate(85px, 0%);
    }
}

.homealertwrapper {
    padding-top: 10px;
    padding-right: 0px;
    padding-left: 0px;
    align-content: center;
}

.homealert {
    max-width: 540px;
    padding-right: 0px;
    padding-left: 0px;
    margin-left: auto;
    margin-right: auto;

    h3 {
        text-align: center;
    }
}

/* 
@media (min-width: 576px) {
    .homealertwrapper {
        padding-top: 10px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .homealert {
        max-width: 540px;
        padding-right: 0px;
        padding-left: 0px;
    }
} */

@media (min-width: 768px) {
    .homealertwrapper {
        padding-top: 20px;
        padding-right: 80px;
        padding-left: 80px;
        align-content: center;
    }

    .homealert {
        padding-right: 15px;
        padding-left: 15px;
        margin-left: auto;
        margin-right: auto;
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .homealertwrapper {
        padding-top: 10px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .homealert {
        max-width: 960px;
    }
}