/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    height: 70px;
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
    background: rgba($secondary, 0.9);

    &.header-transparent {
        background: transparent;
    }

    &.header-scrolled {
        background: rgba($secondary, 0.9);
    }

    .headerwrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding-top: 0px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 0px;
    }

    .logo {
        display: flex;
        flex-direction: row;

        svg {
            width: 48px;
            height: 48px;
            fill: $primary;
            stroke: $primary;
        }

        h1 {
            font-size: 28px;
            margin: 0;
            padding-top: 10px;
            padding-left: 0px;
            padding-right: 0px;
            padding-bottom: 0px;
            line-height: 1;
            font-weight: 700;
            letter-spacing: 1px;
            vertical-align: middle;
        }

        h1 a,
        h1 a:hover {
            color: #fff;
            text-decoration: none;
        }

        img {
            padding: 0;
            margin: 0;
            max-height: 40px;
        }
    }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/

/* Desktop Navigation */
.nav-menu {

    &,
    * {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    >ul>li {
        position: relative;
        white-space: nowrap;
        float: left;
    }

    a {
        display: block;
        position: relative;
        color: $white;
        padding: 5px 15px 7px 15px;
        margin-left: 5px;
        transition: 0.3s;
        font-size: 14px;
        border-radius: 50px;
        font-family: $font-default;
    }

    a:hover,
    .active>a,
    li:hover>a {
        background: $primary;
        text-decoration: none;
    }

    .drop-down ul {
        display: block;
        position: absolute;
        left: 0;
        top: calc(100% - 30px);
        z-index: 99;
        opacity: 0;
        visibility: hidden;
        padding: 10px 0;
        background: #fff;
        box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
        transition: ease all 0.3s;
        border-radius: 15px;
        margin-top: 5px;
    }

    .drop-down:hover>ul {
        opacity: 1;
        top: 100%;
        visibility: visible;
    }

    .drop-down li {
        min-width: 180px;
        position: relative;
    }

    .drop-down ul a {
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 500;
        text-transform: none;
        color: $secondary;
    }

    .drop-down ul a:hover,
    .drop-down ul .active>a,
    .drop-down ul li:hover>a {
        color: $primary;
        background: none;
    }

    .drop-down>a:after {
        content: '\ea99';
        font-family: IcoFont;
        padding-left: 5px;
    }

    .drop-down {
        .drop-down ul {
            top: 0;
            left: calc(100% - 30px);
        }

        .drop-down:hover>ul {
            opacity: 1;
            top: 0;
            left: 100%;
        }

        .drop-down>a {
            padding-right: 35px;
        }

        .drop-down>a:after {
            content: '\eaa0';
            font-family: IcoFont;
            position: absolute;
            right: 15px;
        }
    }
}

@media (max-width: 1366px) {
    .nav-menu .drop-down {
        .drop-down ul {
            left: -90%;
        }

        .drop-down:hover>ul {
            left: -100%;
        }

        .drop-down>a:after {
            content: '\ea9d';
        }
    }
}

/* Mobile Navigation */
.mobile-nav-toggle {
    position: fixed;
    right: 15px;
    top: 15px;
    z-index: 9998;
    border: 0;
    background: none;
    font-size: 24px;
    transition: all 0.4s;
    outline: none !important;
    line-height: 1;
    cursor: pointer;
    text-align: right;

    i {
        color: $white;
    }
}

$mobile_nav_spacing: 15px;

.mobile-nav {
    position: fixed;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    z-index: 9999;
    overflow-y: auto;
    background: #fff;
    transition: ease-in-out 0.2s;
    opacity: 0;
    visibility: hidden;
    border-radius: 10px;
    padding: 10px 0;

    * {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    a {
        display: block;
        position: relative;
        color: $secondary;
        padding: 10px 20px;
        font-weight: 500;
        outline: none;
    }

    a:hover,
    .active>a,
    li:hover>a {
        color: $primary;
        text-decoration: none;
    }

    .drop-down>a:after {
        content: '\ea99';
        font-family: IcoFont;
        padding-left: 10px;
        position: absolute;
        right: 15px;
    }

    .active.drop-down>a:after {
        content: '\eaa1';
    }

    .drop-down>a {
        padding-right: 35px;
    }

    .drop-down ul {
        display: none;
        overflow: hidden;
    }

    .drop-down li {
        padding-left: 20px;
    }
}

.mobile-nav-overly {
    width: 100%;
    height: 100%;
    z-index: 9997;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(darken($secondary, 10), 0.6);
    overflow: hidden;
    display: none;
    transition: ease-in-out 0.2s;
}

.mobile-nav-active {
    overflow: hidden;

    .mobile-nav {
        opacity: 1;
        visibility: visible;
    }

    .mobile-nav-toggle i {
        color: #fff;
    }
}

.btn-header {
    font-family: $font-primary;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    animation-delay: 0.8s;
    border: 2px solid $primary;

    &:hover {
        background: $primary;
        color: #fff;
        text-decoration: none;
    }
}

.headerMenu {
    vertical-align: middle;
    margin-top: 12px;

    a {
        display: inline-block;
        position: relative;
        color: $white;
        padding: 5px 15px 7px 15px;
        margin-left: 5px;
        transition: 0.3s;
        font-size: 14px;
        border-radius: 50px;
        font-family: $font-default;
    }

    a:hover,
    .active>a,
    li:hover>a {
        background: $primary;
        text-decoration: none;
    }
}

.headerMenuItemActive {
    background: $primary;
    text-decoration: none;
}

.headerMenuMobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 12px;

    a {
        text-align: left;
        max-width: 120px;
        display: inline-block;
        position: relative;
        color: $white;
        padding: 12px 15px 12px 15px;
        margin-bottom: 12px;
        margin-left: 5px;
        transition: 0.3s;
        font-size: 14px;
        border-radius: 50px;
        font-family: $font-default;
    }

    a:hover,
    .active>a,
    li:hover>a {
        background: $primary;
        text-decoration: none;
    }
}

.burgerAside {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menuBaseStyles {
    pointer-events: none;
    display: none !important;
    background-color: $default;
    position: absolute;
    z-index: 100;
    top: 0 !important;
    bottom: 0 !important;
    left: -300px !important;
    width: 300px !important;
    transition: all ease 0.5s;
}

.menuOpenStyles {
    pointer-events: all;
    background-color: $default;
    position: absolute;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    width: 300px;
    transition: all ease 0.5s;
}

.overlayBaseStyles {
    opacity: 0;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    display: none;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.overlayOpenStyles {
    opacity: 1;
    transition: opacity 0.5s ease;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    pointer-events: all;
    display: block;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.headerMap {
    i {
        padding-top: 12px;
        font-size: 32px;
        color: $primary;
        float: left;
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        transition: all 0.3s ease-in-out;
    }
}