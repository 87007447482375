/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: $font-default;
  color: $default;
}

a {
  color: $primary;
}

a:hover {
  color: lighten($primary, 10);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  display: none;
  right: 15px;
  bottom: 15px;
  z-index: 99999;

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background: $primary;
    color: #fff;
    transition: all 0.4s;

    &:hover {
      background: lighten($primary, 8);
      color: #fff;
    }
  }

}

/*--------------------------------------------------------------
# Disable AOS delay on mobile
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

.siteLayout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.siteMain {
  flex: 1;
}